import './Leads.css';
import React, { useState, useEffect } from 'react';
import { Link, useNavigate, useOutletContext } from 'react-router-dom';
import api from '../../../api/axiosInstance';
import { getToken } from '../../../utils/secureStorage';
import { useModal } from '../../../contexts/ModalContext';
const roles = getToken('roles');

const Moderate = () => {
  const navigate = useNavigate();
  const [setBreadcrumb, setBreadcrumbLink] = useOutletContext();
  const [cheerBuddy, setCheerBuddys] = useState([]);
  const [pageStart, setPageStart] = useState(1);
  const [pageEnd, setPageEnd] = useState(10);
  const [Total, setTotal] = useState(0);
  const [loading, setLoading] = useState(true);
  const [deletePopup, setDeletePopup] = useState(false);
  const [popupId, setPopupId] = useState('');

  const { openModal } = useModal();

  useEffect(() => {
    setBreadcrumb(['Dashboard', 'Leads']);
    setBreadcrumbLink(['/dashboard']);
  }, [setBreadcrumb, setBreadcrumbLink]);


  const fetchCheerBuddy = async () => {
    try {
      const responseCheerBuddy = await api.get(`/lead/`);
      let limit = responseCheerBuddy.data.pagination.limit;
      let page = responseCheerBuddy.data.pagination.page;
      setCheerBuddys(responseCheerBuddy.data.data);
      setTotal(responseCheerBuddy.data.pagination.total);
      setPageStart(page * limit - limit + 1);
      setPageEnd(page * limit);
      setLoading(false);
    } catch (error) {
      if (error.message === 'Network Error') {
        openModal('failure', 'No Internet Connection');
      } else {
        openModal('failure', error.message);
      }
    }
  };

  useEffect(() => {
    fetchCheerBuddy();
  }, []);

  const deleteCountdown = async (id) => {
    try {
      let res = await api.delete(`/lead/${id}`);
      if (!res.data.error) {
        openModal('success', res.data.message);
        fetchCheerBuddy();
      } else {
      }
    } catch (error) {
      if (error.message === 'Network Error') {
      } else {
        console.error('Error adding post:', error);
      }
    }
  };

  if (cheerBuddy.length < 1 && loading) return (
    <>
      <div className="flex items-center justify-center w-full h-[90vh]">
        <div role="status">
          <svg aria-hidden="true" className="w-8 h-8 text-gray-200 animate-spin dark:text-gray-600 fill-blue-600" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor" /><path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentFill" /></svg>
          <span className="sr-only">Loading...</span>
        </div>
      </div>
    </>
  )
  return (
    <>

      <div id="delete-modal" tabindex="-1" class={`${(deletePopup) ? ('') : ('hidden')} overflow-y-auto overflow-x-hidden fixed top-0 right-0 left-0 z-50 bg-[#878b9385] justify-center flex items-center w-full md:inset-0 h-full max-h-full`}>
        <div class="relative p-4 w-full max-w-md max-h-full">
          <div class="relative bg-white rounded-lg shadow dark:bg-gray-700">

            <div class="p-4 md:p-5 text-center">
              <svg class="mx-auto mb-4 text-gray-400 w-12 h-12 dark:text-gray-200" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 20 20">
                <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M10 11V6m0 8h.01M19 10a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z" />
              </svg>
              <h3 class="mb-5 text-lg font-normal text-gray-500 dark:text-gray-400">Delete Lead with ID {popupId}</h3>
              <button onClick={() => { deleteCountdown(popupId); setDeletePopup(false) }} data-modal-hide="delete-modal" type="button" class="text-white bg-red-600 hover:bg-red-800 focus:ring-4 focus:outline-none focus:ring-red-300 dark:focus:ring-red-800 font-medium rounded-lg text-sm inline-flex items-center px-5 py-2.5 text-center">
                Delete
              </button>
              <button onClick={() => setDeletePopup(false)} type="button" class="py-2.5 px-5 ms-3 text-sm font-medium text-gray-900 focus:outline-none bg-white rounded-lg border border-gray-200 hover:bg-gray-100 hover:text-blue-700 focus:z-10 focus:ring-4 focus:ring-gray-100 dark:focus:ring-gray-700 dark:bg-gray-800 dark:text-gray-400 dark:border-gray-600 dark:hover:text-white dark:hover:bg-gray-700">No, cancel</button>
            </div>
          </div>
        </div>
      </div>
      <div className="p-4 bg-white sm:p-6 dark:bg-gray-800">

        <div className='grid grid-cols-2'>
          <div className='col align-bottom'>
            <h3 className="text-xl pt-3 font-bold text-gray-900 dark:text-white">Leads</h3>
          </div>
          <div className="col text-sm mt-3 mb-2 w-full text-right font-normal text-gray-500 dark:text-gray-400">
            <Link to={{ pathname: `/dashboard/Lead/create` }}>
              <button type="button" className="py-2 px-5 me-2 mb-2 text-xs text-xs font-medium text-gray-900 focus:outline-none bg-white rounded-full border border-gray-200 hover:bg-gray-100 hover:text-blue-700 focus:z-10 focus:ring-4 focus:ring-gray-200 dark:focus:ring-gray-700 dark:bg-gray-800 dark:text-gray-400 dark:border-gray-600 dark:hover:text-white dark:hover:bg-gray-700">Create Lead</button>
            </Link>
            <Link to={{ pathname: `/dashboard/Lead/bulkupload` }}>
              <button type="button" className="py-2 px-5 me-2 mb-2 text-xs text-xs font-medium text-gray-900 focus:outline-none bg-white rounded-full border border-gray-200 hover:bg-gray-100 hover:text-blue-700 focus:z-10 focus:ring-4 focus:ring-gray-200 dark:focus:ring-gray-700 dark:bg-gray-800 dark:text-gray-400 dark:border-gray-600 dark:hover:text-white dark:hover:bg-gray-700">Bulk Upload</button>
            </Link>
          </div>
          <div className='inline-block pt-3 align-top text-sm text-gray-500 mb-2 dark:text-gray-400'>Show <span className='font-semibold'>{pageStart}-{pageEnd}</span> of  <span className='font-semibold'>{Total}</span></div>

        </div>

        <div className="flex flex-col">
          <div className="overflow-x-auto shadow rounded-lg">
            <div className="inline-block min-w-full align-middle">
              <div className="overflow-hidden sm:rounded-lg">
                <table className="min-w-full divide-y divide-gray-200 dark:divide-gray-600">
                  <thead className="bg-gray-50 dark:bg-gray-700">
                    <tr>
                      <th scope="col" className="p-4 text-xs font-medium tracking-wider text-left text-gray-500 uppercase dark:text-white">
                        # ID
                      </th>
                      <th scope="col" className="p-4 text-xs font-medium tracking-wider text-left text-gray-500 uppercase dark:text-white">
                        Name
                      </th>
                      <th scope="col" className="p-4 text-xs font-medium tracking-wider text-left text-gray-500 uppercase dark:text-white">
                        Status
                      </th>
                      <th scope="col" className="p-4 text-xs font-medium tracking-wider text-left text-gray-500 uppercase dark:text-white">
                        Action
                      </th>
                    </tr>
                  </thead>
                  <tbody className="bg-white dark:bg-gray-800">
                    {cheerBuddy.map((post, index) => (

                      <tr key={post._id}>
                        <td className="p-4 text-sm font-normal text-gray-900 whitespace-nowrap dark:text-white">
                          <span className="">{post._id}</span>
                        </td>
                        <td className="p-4 text-sm font-normal whitespace-nowrap">
                          {post.name}
                        </td>
                        <td className="p-4 whitespace-nowrap">
                          <span className="rounded-full capitalize bg-orange-100 text-orange-800 text-xs font-medium mr-2 px-2.5 py-0.5 dark:bg-gray-700 dark:text-orange-400 border border-orange-100 dark:border-orange-500">{post.status}</span>
                        </td>
                        <td className="p-4 text-sm font-normal text-gray-900 whitespace-nowrap dark:text-white">
                          <Link to={{ pathname: `/dashboard/Lead/${post._id}` }}>
                            <button type="button" className="py-2 px-5 me-2 mb-2 text-xs text-xs font-medium text-gray-900 focus:outline-none bg-white rounded-full border border-gray-200 hover:bg-gray-100 hover:text-blue-700 focus:z-10 focus:ring-4 focus:ring-gray-200 dark:focus:ring-gray-700 dark:bg-gray-800 dark:text-gray-400 dark:border-gray-600 dark:hover:text-white dark:hover:bg-gray-700">Edit  Lead</button>
                          </Link>
                          <button onClick={() => { setDeletePopup(true); setPopupId(post._id); }} type="button" class="text-white bg-red-700 hover:bg-red-800 focus:outline-none focus:ring-4 focus:ring-red-300 font-medium rounded-full text-sm px-5 py-2 text-center me-2 mb-2 dark:bg-red-600 dark:hover:bg-red-700 dark:focus:ring-red-900">Delete</button>
                        </td>
                      </tr>
                    ))}
                    {!Total ? (<div className="p-4 text-sm font-normal text-gray-900 whitespace-nowrap dark:text-white">No Leads Yet</div>) : ('')}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>

        <div className="flex items-center justify-end pt-3 sm:pt-6">
          {/* <div>
            <button className="inline-flex items-center p-2 text-sm font-medium text-center text-gray-500 rounded-lg hover:text-gray-900 dark:text-gray-400 dark:hover:text-white" type="button" data-dropdown-toggle="transactions-dropdown">Last 7 days <svg className="w-4 h-4 ml-2" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M19 9l-7 7-7-7"></path></svg></button>
            
            <div className="z-50 hidden my-4 text-base list-none bg-white divide-y divide-gray-100 rounded shadow dark:bg-gray-700 dark:divide-gray-600 pagination" id="transactions-dropdown" data-popper-placement="top" data-popper-reference-hidden="" data-popper-escaped="">
              <div className="px-4 py-3" role="none">
                <p className="text-sm font-medium text-gray-900 truncate dark:text-white" role="none">
                  Sep 16, 2021 - Sep 22, 2021
                </p>
              </div>
              <ul className="py-1" role="none">
                <li>
                  <a href="#" className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 dark:text-gray-400 dark:hover:bg-gray-600 dark:hover:text-white" role="menuitem">Yesterday</a>
                </li>
                <li>
                  <a href="#" className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 dark:text-gray-400 dark:hover:bg-gray-600 dark:hover:text-white" role="menuitem">Today</a>
                </li>
                <li>
                  <a href="#" className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 dark:text-gray-400 dark:hover:bg-gray-600 dark:hover:text-white" role="menuitem">Last 7 days</a>
                </li>
                <li>
                  <a href="#" className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 dark:text-gray-400 dark:hover:bg-gray-600 dark:hover:text-white" role="menuitem">Last 30 days</a>
                </li>
                <li>
                  <a href="#" className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 dark:text-gray-400 dark:hover:bg-gray-600 dark:hover:text-white" role="menuitem">Last 90 days</a>
                </li>
              </ul>
              <div className="py-1" role="none">
                <a href="#" className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 dark:text-gray-400 dark:hover:bg-gray-600 dark:hover:text-white" role="menuitem">Custom...</a>
              </div>
            </div>
          </div> */}
          {roles.includes("admin") ? (
            <div className="flex-shrink-0">
              <Link className="inline-flex items-center p-2 text-xs font-medium uppercase rounded-lg text-primary-700 sm:text-sm hover:bg-gray-100 dark:text-primary-500 dark:hover:bg-gray-700">
                Approval Report
                <svg className="w-4 h-4 ml-1 sm:w-5 sm:h-5" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9 5l7 7-7 7"></path></svg>
              </Link>
            </div>
          ) : (<></>)}
        </div>
      </div>
    </>
  );
};

export default Moderate;
